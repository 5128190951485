@tailwind base;
@tailwind components;
@tailwind utilities;

/*VARIABLES*/
$red: #ef4444;
$darkgrey: #333333;
$textcolor: $darkgrey;

/*MAIN*/
html, body, #root{@apply w-full h-full box-border;}
body{font-size: 16px; color: $textcolor;}

/*FORM*/
.is-invalid{border-bottom: 1px solid $red !important;}
.invalid-feedback{margin-top: 5px; font-size: 13px; color: $red;}
input[type='submit'], form button{
    &:disabled{@apply opacity-50 cursor-not-allowed;}
}

/*ASIDE DASHBOARD NAV*/
.nav-wrapper{min-width: 220px;}

/*BUTTON*/
button{
    a{@apply bg-green-700;}
}

/*TABLE*/
table{
    td{
        line-height: .75rem !important;
        .action, button{
            svg{width: 20px; @apply m-auto cursor-pointer;}
        }
    }
}

/*TEXT*/
.text-wrap{
    text-wrap: wrap;
}